var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `list_${_vm.key}` },
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v(" Grade Templates ")]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-label-success btn-sm mr-2",
                    attrs: { to: { name: "GradeTemplateAssign" } },
                  },
                  [
                    _c("i", { staticClass: "fa fa-list-ul" }),
                    _vm._v(" Assign to Courses "),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-label-primary btn-sm",
                    attrs: {
                      to: {
                        name: "GradeTemplateEditCreate",
                        params: {
                          gradeTemplateId: "new",
                        },
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "flaticon2-plus" }),
                    _vm._v(" Create New Template "),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "kt-portlet" }, [
            _c("div", { staticClass: "kt-portlet__body" }, [
              _c("div", { staticClass: "kt-section" }, [
                _c("div", { staticClass: "kt-section__content" }, [
                  _c("table", { staticClass: "table table-striped" }, [
                    _vm._m(1),
                    _c(
                      "tbody",
                      _vm._l(_vm.templates, function (template) {
                        return _c(
                          "tr",
                          { key: `key_${_vm.key}_${template.gradeTemplateId}` },
                          [
                            _c(
                              "td",
                              { staticClass: "pl-4" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "GradeTemplateEditCreate",
                                        params: {
                                          gradeTemplateId:
                                            template.gradeTemplateId,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    template.schoolDefaultSchoolId
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "flaticon2-correct kt-font-success",
                                          }),
                                        ])
                                      : _vm._e(),
                                    template.mandatory
                                      ? _c("i", {
                                          staticClass:
                                            "la la-lock kt-font-dark",
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(template.gradeTemplateName) +
                                        " "
                                    ),
                                  ]
                                ),
                                template.schoolDefaultSchoolId
                                  ? _c("span", { staticClass: "text-muted" }, [
                                      _c("br"),
                                      _vm._v(" School Default Template "),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("td", [
                              _vm._v(_vm._s(template.scale.scaleDescription)),
                            ]),
                            _c("td", [_vm._v(_vm._s(template.author))]),
                            _c("td", [_vm._v(_vm._s(template.date))]),
                            _c(
                              "td",
                              {
                                staticClass: "row_delete text-center pl-0 pr-4",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "pull-right" },
                                  [
                                    _c(
                                      "b-dropdown",
                                      {
                                        staticClass: "m-1",
                                        attrs: {
                                          "toggle-class": "px-0",
                                          variant: "none",
                                          "no-caret": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button-content",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-clean btn-icon-md",
                                                      attrs: { type: "button" },
                                                    },
                                                    [_vm._v(" Actions ")]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "b-dropdown-header",
                                          {
                                            attrs: {
                                              id: "dropdown-header-label",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  template.gradeTemplateName
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: {
                                              variant: "none",
                                              "button-class": "dropdown-item",
                                              "link-class":
                                                "kt-link kt-font-primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setDefaultTemplate(
                                                  template
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "flaticon2-correct kt-font-success",
                                              staticStyle: {
                                                "margin-top": "-5px",
                                              },
                                            }),
                                            _vm._v(" Set Default "),
                                          ]
                                        ),
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: {
                                              variant: "none",
                                              "button-class": "dropdown-item",
                                              "link-class": "kt-link",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.duplicateTemplate(
                                                  template
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "la la-copy kt-font-primary",
                                            }),
                                            _vm._v(" Duplicate "),
                                          ]
                                        ),
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: {
                                              variant: "none",
                                              "button-class": "dropdown-item",
                                              "link-class":
                                                "kt-link kt-font-danger",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.deleteTemplate(
                                                  template
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "la la-trash kt-font-danger",
                                            }),
                                            _vm._v(" Delete "),
                                          ]
                                        ),
                                        _vm._t("toolbar-dropdown"),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "alert alert-light alert-elevate",
        attrs: { role: "alert" },
      },
      [
        _c("div", { staticClass: "alert-icon" }, [
          _c("i", { staticClass: "flaticon-warning kt-font-brand" }),
        ]),
        _c("div", { staticClass: "alert-text" }, [
          _vm._v(
            " Grading templates are used to calculate Marking Period averages. Each school has one Default School Template. Edit this template to set a default for all courses in the school. You can also create a course template if you need to apply a grade template against some courses in the school. "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Template "), _c("br"), _vm._v("Name")]),
        _c("th", [_vm._v("Scale")]),
        _c("th", [_vm._v("Author")]),
        _c("th", [_vm._v("Date")]),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }