<template>
<div :key="`list_${key}`">
    <GeneralSubHeader>
        <template #title>
            Grade Templates
        </template>
        <template #buttons>
            <router-link
                :to="{ name: 'GradeTemplateAssign' }"
                class="btn btn-label-success btn-sm mr-2"
            >
                <i class="fa fa-list-ul" />
                Assign to Courses
            </router-link>

            <router-link
                :to="{
                    name: 'GradeTemplateEditCreate',
                    params: {
                        gradeTemplateId: 'new'
                    }
                }"
                class="btn btn-label-primary btn-sm"
            >
                <i class="flaticon2-plus" />
                Create New Template
            </router-link>
        </template>
    </GeneralSubHeader>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div
            class="alert alert-light alert-elevate"
            role="alert"
        >
            <div class="alert-icon">
                <i class="flaticon-warning kt-font-brand" />
            </div>
            <div class="alert-text">
                Grading templates are used to calculate Marking Period averages. Each school has one Default School Template.
                Edit this template to set a default for all courses in the school. You can also
                create a course template if you need to apply a grade template against some courses in the school.
            </div>
        </div>

        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <div class="kt-section">
                    <div class="kt-section__content">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Template <br>Name</th>
                                    <th>Scale</th>
                                    <th>Author</th>
                                    <th>Date</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="template in templates"
                                    :key="`key_${key}_${template.gradeTemplateId}`"
                                >
                                    <td class="pl-4">
                                        <router-link
                                            :to="{
                                                name: 'GradeTemplateEditCreate',
                                                params: {
                                                    gradeTemplateId: template.gradeTemplateId
                                                }
                                            }"
                                        >
                                            <span v-if="template.schoolDefaultSchoolId">
                                                <i class="flaticon2-correct kt-font-success" />
                                            </span>
                                            <i
                                                v-if="template.mandatory"
                                                class="la la-lock kt-font-dark"
                                            />

                                            {{ template.gradeTemplateName }}
                                        </router-link>
                                        <span v-if="template.schoolDefaultSchoolId" class="text-muted">
                                            <br>
                                            School Default Template
                                        </span>
                                    </td>
                                    <td>{{ template.scale.scaleDescription }}</td>
                                    <td>{{ template.author }}</td>
                                    <td>{{ template.date }}</td>
                                    <td class="row_delete text-center pl-0 pr-4">
                                        <div class="pull-right">
                                            <b-dropdown
                                                class="m-1"
                                                toggle-class="px-0"
                                                variant="none"
                                                no-caret
                                            >
                                                <b-dropdown-header id="dropdown-header-label">
                                                    {{ template.gradeTemplateName }}
                                                </b-dropdown-header>
                                                <template #button-content>
                                                    <button
                                                        type="button"
                                                        class="btn btn-sm btn-clean btn-icon-md"
                                                    >
                                                        Actions
                                                    </button>
                                                </template>
                                                <b-dropdown-item
                                                    variant="none"
                                                    button-class="dropdown-item"
                                                    link-class="kt-link kt-font-primary"
                                                    @click="setDefaultTemplate(template)"
                                                >
                                                    <i class="flaticon2-correct kt-font-success" style="margin-top: -5px;" />
                                                    Set Default
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    variant="none"
                                                    button-class="dropdown-item"
                                                    link-class="kt-link"
                                                    @click="duplicateTemplate(template)"
                                                >
                                                    <i class="la la-copy kt-font-primary" />
                                                    Duplicate
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    variant="none"
                                                    button-class="dropdown-item"
                                                    link-class="kt-link kt-font-danger"
                                                    @click.stop.prevent="deleteTemplate(template)"
                                                >
                                                    <i class="la la-trash kt-font-danger" />
                                                    Delete
                                                </b-dropdown-item>

                                                <slot name="toolbar-dropdown" />
                                            </b-dropdown>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import moment from 'moment';
import * as network from '../network';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import Types from '../store/Types';
import { callback } from '../network/googleAuth';

export default {
    name: 'GradeTemplateList',
    components: {
        GeneralSubHeader,
    },
    data() {
        return {
            key: 0,
            saving: false,
        };
    },
    computed: {
        templates() {
            return this.$store.state.database.gradeTemplates.map((t) => {
                const template = t;
                template.lastEdit = template.modified || template.created;
                template.date = moment(template.lastEdit).format('ddd, MMM Do');
                return template;
            }).filter((t) => !t.deleted);
        },
        ...mapState({
            user: (state) => state.user,
        }),
    },
    watch: {
        templates() {
            this.key += 1;
        },
    },
    methods: {
        setDefaultTemplate(template) {
            const { schoolId, schoolTermId } = this.user.school;
            const { gradeTemplateId } = template;
            const { showError, reloadTemplates } = this;
            const params = {
                url: { schoolId, schoolTermId, gradeTemplateId },
                body: null,
            };
            const v = this;
            network.gradeTemplates.setDefaultTemplate(params, (err) => {
                v.saving = false;
                if (err) return showError(err);
                reloadTemplates();
            });
        },
        duplicateTemplate(template) {
            const {showError, reloadTemplates} = this;
            const gradeTemplateName = prompt('Enter a new name for the duplicated template', `${template.gradeTemplateName} (copy)`);
            if (!gradeTemplateName) return;
            const exists = this.templates.find((t) => t.gradeTemplateName === gradeTemplateName);
            if (exists) return alert('A template with that name already exists');
            const { $store } = this;
            const { schoolId, schoolTermId } = this.user.school;
            const { gradeTemplateId } = template;
            const params = {
                url: { schoolId, schoolTermId, gradeTemplateId },
                body: { gradeTemplateName },
            };
            const v = this;
            network.gradeTemplates.duplicateTemplate(params, (err) => {
                v.saving = false;
                if (err) return showError(err);
                reloadTemplates();
            });
        },
        deleteTemplate(template) {
            const v = this;
            const { showError, reloadTemplates } = v;

            const message = 'Are you sure you want to delete this template?';
            v.$bvModal
                .msgBoxConfirm(message, {
                    title: 'Please Confirm Deletion',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'DELETE',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: true,
                })
                .then((approved) => {
                    if (!approved) return;
                    v.saving = true;
                    const { schoolId, schoolTermId } = v.user.school;
                    const { gradeTemplateId } = template;
                    const params = {
                        url: { schoolId, schoolTermId, gradeTemplateId },
                        body: null,
                    };
                    network.gradeTemplates.deleteGradingTemplate(params, (err) => {
                        v.saving = false;
                        if (err) return showError(err);
                        reloadTemplates();
                    });
                });
        },
        reloadTemplates() {
            const callback = function() {};
            this.$store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, { tableName: 'gradeTemplates', callback});
        },
    },
};
</script>

<style scoped>
.table tbody td {
    vertical-align: middle;
}
</style>
